import React from "react";

// Styles
import "./request-a-demo.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";

// Images and icons
import CurveBG from "../assets/images/curve-bg.svg";
import NetlifyForm from "react-netlify-form";

const RequestADemo = () => {
  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Page>
        <section className="section-demo">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${CurveBG}')` }}
          ></div>
          <div className="main-container-large">
            <div className="contact-wrapper">
              <div className="contact-video">
                <div className="video-wrapper">
                  <iframe
                    frameBorder="0"
                    width="100%"
                    height="300"
                    title="Skodel"
                    src="https://www.youtube.com/embed/EjNXegR3OKo?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
                  />
                </div>
                <h3>Get to know Skodel</h3>
                <p>
                  We’re here to help leaders meet psychosocial safety
                  requirements fast and effectively.
                </p>
              </div>
              <div className="contact-form">
                <NetlifyForm name="Book Demo">
                  {({ loading, error, success }) => (
                    <>
                      {success && (
                        <div>
                          Thanks for booking a demo with us. We'll contact you
                          soon!
                        </div>
                      )}
                      {!success && (
                        <>
                          <h3>Schedule a demo</h3>
                          <p>
                            Get a live demo and have your questions answered
                            with one of our product experts.
                          </p>
                          <div className="contact-form-wrapper">
                            <div className="form-wrapper">
                              <div className="form-grid column-2">
                                <div className="form-group">
                                  <label>First Name</label>
                                  <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Enter first name"
                                    name="firstName"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Last Name</label>
                                  <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Enter last name"
                                    name="lastName"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Company Name</label>
                                <input
                                  className="form-input"
                                  type="text"
                                  placeholder="Enter company name"
                                  name="companyName"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  className="form-input"
                                  type="email"
                                  placeholder="Enter email address"
                                  name="email"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label>What interests you in Skodel?</label>
                                <textarea
                                  className="form-input"
                                  placeholder=""
                                  name="interest"
                                />
                              </div>
                              {error && (
                                <div className="error-msg">
                                  Your information was not sent. Please try
                                  again later.
                                </div>
                              )}
                              <div className="form-group">
                                <button
                                  className="btn btn-main btn-contact btn-full"
                                  disabled={loading}
                                >
                                  {loading ? "Sending..." : "Get Started"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </NetlifyForm>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default RequestADemo;
